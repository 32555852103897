import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { ContentSection } from "@ryerson/frontend.layout";
import { Badge } from "@ryerson/frontend.notification";
import { Link } from "@ryerson/frontend.navigation";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import { Icon } from "@ryerson/frontend.assets";
import { css } from "@emotion/react";
import MaterialMovers from "./MaterialMovers";
import MarketReports from "./MarketReports";
import StayTunedComponent from "@components/Shared/StayTuned/StayTuned";

import {
	BlogCategory,
	GetCategoryBackground,
	GetCategoryTitle,
	BlogURLCategories,
	GetCategoryDescription,
	blogCategories,
	BlogBadgeColor,
	TranslateBlogCategory,
} from "./Blog";

export type BlogLandingStaticContent = {
	title: string;
	subTitle: string[];
	subTitleLogo: string;
	loadMore: string;
	categories: BlogCategory[];
};

export type NewsLandingProps = {
	content: BlogLandingStaticContent;
};

const BreadcrumbContainer = styled.div`
	padding-top: 52px;
	position: relative;
	z-index: 1;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				padding-top: 28px;
			}
		`;
	}}
`;

const NavContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
	box-sizing: border-box;
`;

const NavItem = styled.div`
	display: inline-block;
	height: 58px;
	line-height: 57px;
	cursor: pointer;
	width: auto;
`;

const Divider = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	margin: 0px;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 10px;
`;

const BigSpacer = styled.div`
	display: block;
	width: 100%;
	height: 70px;
`;

const LoadMore = styled.div`
	display: block;
	margin: 0 auto;
	cursor: pointer;
	width: 210px;
	box-sizing: border-box;
	line-height: 36px;
	text-align: center;
	height: 40px;
	border-radius: 24px;
`;

const HeroContainer = styled.div`
	display: block;
	width: 100%;
	padding-top: 85px;
	box-sizing: border-box;
	height: auto;
	margin-bottom: 50px;
	position: relative;
`;

const MobileDivider = styled.hr`
	display: block;
	width: 100%;
	opacity: 0.1;
`;

const CategorySelector = styled.span`
	display: inline-block;
`;

const RevealBox = styled.div`
	width: calc(100% - 40px);
	position: absolute;
	top: 60px;
	left: 20px;
	height: 0px;
	overflow: hidden;
	padding: 0px 20px;
	box-sizing: border-box;
	box-shadow: 0px 10px 80px rgb(0 0 0 / 7%), 0px 10px 17.869px rgb(0 0 0 / 4%),
		0px 5px 5.32008px rgb(0 0 0 / 3%);
	background-color: #ffffff;
	z-index: 1;
`;

const HeroImage = styled.div`
	display: block;
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

const PoweredBy = styled.div`
	display: block;
	width: 250px;
	height: 72px;
	position: absolute;
	top: 115px;
	right: 0px;
	text-align: right;
	img {
		display: block;
		margin: 9px 0px 0px auto;
		height: 15px;
		width: auto;
	}
`;

const FirstBlog = styled.div`
	display: block;
	width: 100%;
	height: 400px;
`;

const FirstBlogImage = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 660px;
	height: 400px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	margin-right: 140px;
`;

const FirstBlogTitle = styled.div`
	display: inline-block;
	width: calc(100% - 800px);
	position: relative;
	height: 400px;
`;

const BlogItem = styled.div`
	display: block;
	width: 100%;
	height: 180px;
`;

const BlogItemImage = styled.div`
	display: inline-block;
	width: 300px;
	margin-right: 100px;
	vertical-align: top;
	height: 180px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

const BlogTitle = styled.div`
	display: inline-block;
	width: 260px;
	vertical-align: top;
	position: relative;
	height: 180px;
	margin-right: 140px;
`;

const BlogTitleBadge = styled.div`
	display: block;
	width: 100%;
	left: 0;
	bottom: 0;
	height: 30px;
	position: absolute;
`;

const BlogBlurb = styled.div`
	display: inline-block;
	width: calc(100% - 800px);
	padding-top: 5px;
	box-sizing: border-box;
	height: 180px;
`;

const MobileBlogItem = styled.div`
	display: block;
	width: 100%;
	margin-bottom: 40px;
	height: auto;
`;

const MobileBlogItemImage = styled.div`
	display: block;
	width: 100%;
	max-width: 300px;
	margin: 0 auto;
	position: relative;
	height: 180px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

const MobileBlogTitleBadge = styled.div`
	display: block;
	width: 100%;
	top: 20px;
	left: 20px;
	height: 24px;
	width: calc(100% - 40px);
	position: absolute;
`;

const RelativeContentSection = styled(ContentSection)`
	position: relative;
`;

const stayTunedParent = "Blogs-Landing";

const Landing: React.FC<NewsLandingProps> = ({ content }) => {
	const {
		localization: { language },
	} = useApplication();
	const { theme } = useTheme();
	const [filter, setFilter] = React.useState<BlogURLCategories>(
		window?.location.hash.replace("#", "") as BlogURLCategories
	);
	const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false);

	const queryData = useStaticQuery(graphql`
		query AllBlogsMetaQuery {
			allContentfulBlog(sort: { order: DESC, fields: contentful_id }) {
				nodes {
					slug
					category
					title
					heroImage {
						file {
							url
						}
					}
					contentful_id
					publishDate
					node_locale
					seoDescription
				}
			}
		}
	`);

	let recentBlogs: any = [];
	let previousId: any = null;
	let i = -1;
	queryData?.allContentfulBlog?.nodes?.forEach((node: any) => {
		if (previousId !== node?.contentful_id) {
			i++;
			recentBlogs[i] = {};
		}
		recentBlogs[i][node?.node_locale.split("-").shift()] = node;
		recentBlogs[i].date = node?.publishDate;
		recentBlogs[i].category = node?.category;
		for (let x = 0; x < [blogCategories[language]].length; x++) {
			if (node?.category === blogCategories[language][x].label) {
				recentBlogs[i].category = blogCategories[language][x].url;
				break;
			}
		}
		previousId = node.contentful_id;
	});
	recentBlogs.sort(function (a: any, b: any) {
		return new Date(b.date).valueOf() - new Date(a.date).valueOf();
	});

	const [slicePoint, setSlicePoint] = React.useState<number>(8);

	const [currentBlogs, setCurrentBlogs] = React.useState(recentBlogs);

	const [mobileSlicePoint, setMobileSlicePoint] = React.useState<number>(8);
	if (window) {
		React.useEffect(() => {
			if (window) {
				let hash = window.location.hash.replace("#", "") as BlogURLCategories;
				if (hash !== filter) setFilter(hash);
			}
		}, [window.location.hash]);
	}
	React.useEffect(() => {
		if (filter === "") {
			setCurrentBlogs(recentBlogs);
			if (window) window.location.hash = "";
		} else {
			let newCurrent: any[] = [];
			recentBlogs.forEach((item: any, index: number) => {
				if (TranslateBlogCategory(item.category) === filter) newCurrent.push(item);
			});
			setSlicePoint(8);
			setMobileSlicePoint(8);
			setCurrentBlogs(newCurrent);
			if (window && window.location.hash !== "#" + filter) window.location.hash = filter;
		}
		if (categoryRefs[filter] && categoryRefs[filter].current && window && document) {
			let rect = categoryRefs[filter].current.getBoundingClientRect();
			let visible =
				rect.bottom > 0 &&
				rect.right > 0 &&
				rect.left <
					(window.innerWidth ||
						document.documentElement.clientWidth) /* or $(window).width() */ &&
				rect.top <
					(window.innerHeight ||
						document.documentElement.clientHeight); /* or $(window).height() */
			if (!visible) {
				window.scrollTo({ top: 0, behavior: "smooth" });
			}
		}
	}, [filter]);

	const categoryRefs: any = {
		"": React.useRef(),
		"market-updates": React.useRef(),
		"grade-anatomy": React.useRef(),
		"metal-processing": React.useRef(),
		"industry-trends": React.useRef(),
		"metal-perspective": React.useRef(),
		"success-stories": React.useRef(),
	};

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="0px" type="tertiary">
					<HeroImage
						css={css`
							background-image: url(${GetCategoryBackground(filter, language)
								.imageUrl});
							${GetCategoryBackground(filter, language).imagePosition === "top-right"
								? "background-position:top right; background-size:auto auto"
								: ""}
						`}
					/>
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<HeroContainer
						css={
							GetCategoryBackground(filter, language).imagePosition === "top-right"
								? css`
										margin-bottom: 0px;
										padding-top: 20px;
								  `
								: css`
										margin-bottom: 50px;
										padding-top: 85px;
								  `
						}
					>
						{GetCategoryBackground(filter, language).imagePosition === "top-right" ? (
							<></>
						) : (
							<>
								<Typography
									variant="h3"
									type="tertiary"
									css={css`
										margin-top: 0px;
										letter-spacing: -1px;
										margin-bottom: 15px;
									`}
								>
									{content.title}
								</Typography>
								<PoweredBy>
									<Typography
										variant="h3"
										type="tertiary"
										css={css`
											font-size: 20px;
											line-height: 24px;
											letter-spacing: -1px;
										`}
									>
										{content.subTitle[0] ?? ""}
									</Typography>
									<Typography
										variant="h3"
										type="tertiary"
										css={css`
											font-size: 20px;
											line-height: 24px;
											letter-spacing: -1px;
										`}
									>
										{content.subTitle[1] ?? ""}
									</Typography>
									<img src={content.subTitleLogo} />
								</PoweredBy>
							</>
						)}

						<Typography
							variant="h1"
							type="tertiary"
							css={css`
								margin-bottom: 20px;
								font-size: 75px;
								max-width: calc(100% - 240px);
								letter-spacing: -2px;
								@media (max-width: ${theme.breakpoints.xl}) {
									font-size: 56px;
								}
							`}
							weight="bolder"
						>
							{GetCategoryTitle(filter, language)}
						</Typography>
						<Typography
							variant="div"
							css={css`
								margin: 0px;
								white-space: pre-line;
							`}
							color={theme.colors.tertiary.header}
							size="sm"
						>
							{GetCategoryDescription(filter, language)}
						</Typography>
					</HeroContainer>
					<Spacer
						css={css`
							height: 47px;
						`}
					/>
				</ContentSection>
				<NavContainer
					css={css`
						background-color: ${theme.colors.primary.background};
						position: relative;
					`}
				>
					<ContentSection vPadding="0px" type="primary">
						{content.categories.map((category: BlogCategory, index: number) => {
							return (
								<NavItem
									key={index}
									css={css`
										margin-right: ${index === content.categories.length - 1
											? "0px"
											: "40px"};
										border-bottom: ${filter === category.url
											? "1px solid " + theme.colors.primary.link
											: "1px solid transparent"};
									`}
									ref={categoryRefs[category.url] ?? null}
									onClick={() => {
										setFilter(category.url);
									}}
								>
									<Typography
										variant="span"
										type="secondary"
										color={theme.colors.primary.header}
										size="md"
										weight="bold"
										css={css`
											display: block;
											height: 57px;
											line-height: 57px;
										`}
									>
										{category.label}
									</Typography>
								</NavItem>
							);
						})}
					</ContentSection>
					<Divider
						css={css`
							border-bottom: 1px solid ${theme.colors.primary.darkerGray};
							opacity: 0.1;
							position: absolute;
							bottom: 0px;
							left: 0px;
						`}
					/>
				</NavContainer>

				{filter === "market-updates" ? (
					<MarketReports />
				) : (
					<>
						<ContentSection type="primary" vPadding="35px">
							<Spacer
								css={css`
									height: 65px;
								`}
							/>
							{currentBlogs.slice(0, 1).map((blog: any, index: number) => {
								const url = blog[language]?.heroImage?.file?.url;
								const heroImg = url ? url + "?fm=jpg&fit=fill&w=660&h=400" : "";
								return (
									<React.Fragment key={index}>
										<FirstBlog>
											<FirstBlogImage
												css={css`
													background-image: url(${heroImg});
												`}
											/>

											<FirstBlogTitle>
												<Link
													to={
														"/metal-resources/metal-market-intelligence/" +
														blog[language]?.slug
													}
												>
													<Typography
														variant="h2"
														type="primary"
														css={css`
															margin: 0px 0px 48px 0px;
														`}
													>
														{blog[language]?.title}
													</Typography>
												</Link>
												<Typography variant="div" size="md" type="primary">
													{blog[language]?.seoDescription}
												</Typography>
												<BlogTitleBadge>
													<Badge
														type="primary"
														color={BlogBadgeColor(
															TranslateBlogCategory(
																blog[language]?.category
															)
														)}
													>
														{GetCategoryTitle(
															TranslateBlogCategory(
																blog[language]?.category
															),
															language
														)}
													</Badge>
												</BlogTitleBadge>
											</FirstBlogTitle>
										</FirstBlog>
										<Divider
											css={css`
												border-bottom: 1px solid
													${theme.colors.primary.darkerGray};
												opacity: 0.1;
												margin-top: 68px;
												margin-bottom: 39px;
											`}
										/>
									</React.Fragment>
								);
							})}
							{currentBlogs.slice(1, 5).map((blog: any, index: number) => {
								const url = blog[language]?.heroImage?.file?.url;
								const heroImg = url ? url + "?fm=jpg&fit=fill&w=300&h=180" : "";
								return (
									<React.Fragment key={index}>
										<BlogItem>
											<BlogItemImage
												css={css`
													background-image: url(${heroImg});
												`}
											/>
											<BlogTitle>
												<Link
													to={
														"/metal-resources/metal-market-intelligence/" +
														blog[language]?.slug
													}
												>
													<Typography
														variant="h3"
														type="primary"
														css={css`
															margin: 0px;
														`}
													>
														{blog[language]?.title}
													</Typography>
												</Link>
												<BlogTitleBadge>
													<Badge
														type="primary"
														color={BlogBadgeColor(
															TranslateBlogCategory(
																blog[language]?.category
															)
														)}
													>
														{GetCategoryTitle(
															TranslateBlogCategory(
																blog[language]?.category
															),
															language
														)}
													</Badge>
												</BlogTitleBadge>
											</BlogTitle>
											<BlogBlurb>
												<Typography variant="div" size="md" type="primary">
													{blog[language]?.seoDescription}
												</Typography>
											</BlogBlurb>
										</BlogItem>
										<Divider
											css={css`
												border-bottom: 1px solid
													${theme.colors.primary.darkerGray};
												opacity: 0.1;
												margin-top: 40px;
												margin-bottom: 39px;
											`}
										/>
									</React.Fragment>
								);
							})}
						</ContentSection>
						<StayTunedComponent parent={stayTunedParent} parentCategory={filter} />
						<ContentSection type="primary" vPadding="80px">
							{filter === "" ? <MaterialMovers /> : <></>}
							{currentBlogs.slice(5, slicePoint).length > 0 ? (
								<Divider
									css={css`
										border-bottom: 1px solid ${theme.colors.primary.darkerGray};
										opacity: 0.1;
										margin-top: 84px;
										margin-bottom: 39px;
									`}
								/>
							) : (
								<></>
							)}

							{currentBlogs.slice(5, slicePoint).map((blog: any, index: number) => {
								const url = blog[language]?.heroImage?.file?.url;
								const heroImg = url ? url + "?fm=jpg&fit=fill&w=300&h=180" : "";
								return (
									<React.Fragment key={index}>
										<BlogItem>
											<BlogItemImage
												css={css`
													background-image: url(${heroImg});
												`}
											/>
											<BlogTitle>
												<Link
													to={
														"/metal-resources/metal-market-intelligence/" +
														blog[language]?.slug
													}
												>
													<Typography
														variant="h3"
														type="primary"
														css={css`
															margin: 0px;
														`}
													>
														{blog[language]?.title}
													</Typography>
												</Link>
												<BlogTitleBadge>
													<Badge
														type="primary"
														color={BlogBadgeColor(
															TranslateBlogCategory(
																blog[language]?.category
															)
														)}
													>
														{GetCategoryTitle(
															TranslateBlogCategory(
																blog[language]?.category
															),
															language
														)}
													</Badge>
												</BlogTitleBadge>
											</BlogTitle>
											<BlogBlurb>
												<Typography variant="div" size="md" type="primary">
													{blog[language]?.seoDescription}
												</Typography>
											</BlogBlurb>
										</BlogItem>
										<Divider
											css={css`
												border-bottom: 1px solid
													${theme.colors.primary.darkerGray};
												opacity: 0.1;
												margin-top: 40px;
												margin-bottom: 39px;
											`}
										/>
									</React.Fragment>
								);
							})}
							{currentBlogs.length - 1 > slicePoint ? (
								<LoadMore
									css={css`
										border: 1px solid ${theme.colors.primary.gray};
									`}
									onClick={() => {
										setSlicePoint(slicePoint + 4);
									}}
								>
									<Typography
										variant="span"
										color={theme.colors.primary.darkGray}
										weight="bold"
										size="sm"
									>
										{content.loadMore}
									</Typography>
								</LoadMore>
							) : (
								<></>
							)}
							<BigSpacer />
							<Spacer />
						</ContentSection>
					</>
				)}
			</Media>
			<Media lessThan="lg">
				<ContentSection vPadding="0px" type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						type="tertiary"
						variant="h1"
						css={css`
							letter-spacing: -2px;
							margin-top: 12px;
							margin-bottom: 20px;
						`}
					>
						{GetCategoryTitle(filter, language)}
					</Typography>
					<Typography
						type="tertiary"
						variant="div"
						size="md"
						css={css`
							height: 250px;
							width: 100%;
							position: absolute;
							left: 0;
							padding: 0 20px 0 20px;
						`}
					>
						{GetCategoryDescription(filter, language)}
						<img
							src="/images/metal-resources/metal-market-intelligence/MobileMetalMarketIntelligence.png"
							css={css`
								display: block;
								position: absolute;
								right: 0;
								bottom: -40px;
							`}
						/>
					</Typography>
					<Spacer
						css={css`
							height: 250px;
						`}
					/>
				</ContentSection>
				<Spacer />
				<Spacer />
				<RelativeContentSection type="primary" hPadding="0px" vPadding="0px">
					<Typography
						variant="div"
						size="lg"
						css={css`
							text-align: right;
							height: 60px;
							line-height: 60px;
							padding-right: 15px;
							box-sizing: border-box;
						`}
						color={theme.colors.primary.link}
					>
						{content.categories.map((category: BlogCategory, index: number) => {
							return (
								<React.Fragment key={index}>
									{category.url === filter ? (
										<CategorySelector
											onClick={() => {
												setShowMobileNav(showMobileNav ? false : true);
											}}
											ref={categoryRefs[category.url] ?? null}
										>
											{category.label}
											<Icon
												icon="arrow-down"
												css={css`
													cursor: pointer;
													margin-left: 8px;
												`}
												size="xs"
												color={theme.colors.primary.link}
											/>
										</CategorySelector>
									) : (
										<></>
									)}
								</React.Fragment>
							);
						})}
					</Typography>
					<RevealBox
						css={css`
							background-color: ${theme.colors.primary.background};
							height: ${showMobileNav === true ? "315px" : "0px"};
							transition: height 0.25s ease;
						`}
					>
						<Spacer
							css={css`
								height: 24px;
							`}
						/>
						{content.categories.map((category: BlogCategory, index: number) => {
							return (
								<React.Fragment key={index}>
									<Typography
										variant="div"
										color={
											filter === category.url
												? theme.colors.primary.gray
												: theme.colors.primary.header
										}
										size="md"
										weight="bold"
										css={css`
											display: block;
											height: 20px;
											line-height: 20px;
											margin-bottom: 10px;
										`}
									>
										<CategorySelector
											onClick={() => {
												setFilter(category.url);
												setShowMobileNav(showMobileNav ? false : true);
											}}
										>
											{category.label}
										</CategorySelector>
									</Typography>
									{index === content.categories.length - 1 ? (
										<></>
									) : (
										<MobileDivider
											css={css`
												color: ${theme.colors.primary.darkerGray};
												margin-bottom: 10px;
											`}
										/>
									)}
								</React.Fragment>
							);
						})}
					</RevealBox>

					<Spacer />
					<Spacer />
					{filter === "market-updates" ? (
						<MarketReports />
					) : (
						<>
							<ContentSection type="primary" vPadding="0px">
								{currentBlogs.slice(0, 5).map((blog: any, index: number) => {
									const url = blog[language]?.heroImage?.file?.url;
									const heroImg = url ? url + "?fm=jpg&fit=fill&w=300&h=180" : "";
									return (
										<Link
											to={
												"/metal-resources/metal-market-intelligence/" +
												blog[language]?.slug
											}
											key={index}
										>
											<MobileBlogItem>
												<MobileBlogItemImage
													css={css`
														background-image: url(${heroImg});
													`}
												>
													<MobileBlogTitleBadge>
														<Badge
															type="tertiary"
															size="sm"
															color={BlogBadgeColor(
																TranslateBlogCategory(
																	blog[language]?.category
																)
															)}
														>
															{GetCategoryTitle(
																TranslateBlogCategory(
																	blog[language]?.category
																),
																language
															)}
														</Badge>
													</MobileBlogTitleBadge>
												</MobileBlogItemImage>
												<Typography
													variant="h3"
													type="primary"
													css={css`
														margin-top: 25px;
														margin-bottom: 16px;
													`}
												>
													{blog[language]?.title}
												</Typography>
												<Typography variant="div" size="md" type="primary">
													{blog[language]?.seoDescription}
												</Typography>
											</MobileBlogItem>
										</Link>
									);
								})}
							</ContentSection>
							<Spacer />
							<StayTunedComponent parent={stayTunedParent} parentCategory={filter} />
							{filter === "" ? <MaterialMovers /> : <></>}
							<ContentSection type="primary" vPadding="48px">
								{currentBlogs
									.slice(5, mobileSlicePoint)
									.map((blog: any, index: number) => {
										const url = blog[language]?.heroImage?.file?.url;
										const heroImg = url
											? url + "?fm=jpg&fit=fill&w=300&h=180"
											: "";
										return (
											<Link
												to={
													"/metal-resources/metal-market-intelligence/" +
													blog[language]?.slug
												}
												key={index}
											>
												<MobileBlogItem>
													<MobileBlogItemImage
														css={css`
															background-image: url(${heroImg});
														`}
													>
														<MobileBlogTitleBadge>
															<Badge
																type="tertiary"
																size="sm"
																color={BlogBadgeColor(
																	TranslateBlogCategory(
																		blog[language]?.category
																	)
																)}
															>
																{GetCategoryTitle(
																	TranslateBlogCategory(
																		blog[language]?.category
																	),
																	language
																)}
															</Badge>
														</MobileBlogTitleBadge>
													</MobileBlogItemImage>
													<Typography
														variant="h3"
														type="primary"
														css={css`
															margin-top: 25px;
															margin-bottom: 16px;
														`}
													>
														{blog[language]?.title}
													</Typography>
													<Typography
														variant="div"
														size="md"
														type="primary"
													>
														{blog[language]?.seoDescription}
													</Typography>
												</MobileBlogItem>
											</Link>
										);
									})}
								{currentBlogs.length - 1 > mobileSlicePoint ? (
									<LoadMore
										css={css`
											border: 1px solid ${theme.colors.primary.gray};
										`}
										onClick={() => {
											setMobileSlicePoint(mobileSlicePoint + 4);
										}}
									>
										<Typography
											variant="span"
											color={theme.colors.primary.darkGray}
											weight="bold"
											size="sm"
										>
											{content.loadMore}
										</Typography>
									</LoadMore>
								) : (
									<></>
								)}
							</ContentSection>
						</>
					)}
				</RelativeContentSection>
			</Media>
		</>
	);
};

export default Landing;
